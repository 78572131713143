<template>
    <div class="header_nav_box">
        <div class="second_title">
          泰山云mes平台
        </div>
        <div class="second_title second_title_right">
          工业互联网子平台
        </div>
    </div>
</template>
<script>
export default {
  components: {
  },
  data(){
      return{

      }
  },
  computed: {
    currentPage() {
      console.log(this.$route.name,'lujing')
      return this.$route.name;
    },
  },

};
</script>