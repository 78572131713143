<template>
  <div class="bigdata_page">
    <bigdata-header></bigdata-header>
    <div class="main_box">
       <router-view />
    </div>
  </div>
</template>
<script>
import bigdataHeader from "@/components/bigdata-header";
export default {
  components: {
    bigdataHeader
  }
};
</script>